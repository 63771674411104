
import { ChartGenerator } from '@/models/Charts/abstract/chartGenerator';
import { BendsPerPartDataPerDevice } from '@/models/Charts/bendsPerPartKpiWidgetGenerator';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { Component, Prop, Vue } from 'vue-property-decorator';
import KpiWidget from '../common/KpiWidget.vue';

@Component({
  components: {
    KpiWidget,
  },
})
export default class BendsPerPartKpiWidget extends Vue {
  @Prop({ required: true })
  private data!: BendsPerPartDataPerDevice[] | null;

  @Prop({ required: true })
  private generator!: ChartGenerator<BendsPerPartDataPerDevice>;

  @Prop({ required: true })
  private widgetDefinition!: WidgetDefinition;

  @Prop({ required: true, type: Boolean })
  private loading!: boolean;

  @Prop({ required: true, type: Boolean })
  private isFetchingForTheFirstTime!: boolean;

  private mappingFunction(entry: BendsPerPartDataPerDevice) {
    return entry.bendsPerPart;
  }
}
